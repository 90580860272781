import { db } from '@/firebase'
import { collection, doc, deleteDoc, getDocs } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 回答結果
    // { qid: [{}, {}, ...], qid: [], ... }
    answers: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 回答結果の一覧
   */
  answers: state => state.answers
}

const mutations = {
  /**
   * 回答結果の一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.qid 質問のドキュメントID
   * @param {Object} payload.answer 回答情報
   */
  setAnswer: (state, payload) => {
    if (!state.answers[payload.qid]) state.answers[payload.qid] = []
    state.answers[payload.qid].push(payload.answer)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 診断の質問回答履歴を取得
   * @param {Object} payload 引数
   * @param {String} payload.rid 診断結果のドキュメントID
   * @param {String} payload.uid ユーザid
   * @return {Object[]} 質問の回答履歴 [ { aid: , ... }, ... ]
   */
  async getAnswers ({ commit }, payload) {
    try {
      const uid = payload.uid || ''
      const snapshot = await getDocs(collection(db, 'results', payload.rid, 'answers'))

      const answers = []
      snapshot.forEach(doc => {
        const answer = Object.assign(doc.data(), { aid: doc.id, uid: uid })

        answers.push(Object.assign(doc.data(), { aid: doc.id, uid: uid }))
        commit('setAnswer', { qid: doc.data().qid, answer: answer })
      })
      return answers
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 診断の回答履歴を削除
   * @param {Object} payload 引数
   * @param {String} payload.rid 診断結果のドキュメントID
   * @param {String} payload.aid 診断結果の回答履歴のドキュメントID
   */
  async deleteAnswer ({ commit }, payload) {
    try {
      const docRef = doc(db, 'results', payload.rid, 'answers', payload.aid)
      await deleteDoc(docRef, params)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
