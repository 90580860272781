import { db } from '@/firebase'
import { collection, doc, addDoc, getDocs, updateDoc } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 診断結果のオブジェクト一覧
    // { cid: {}, cid: {}, ... }
    checks: {},
    // 脳タイプ診断のドキュメントID
    brainCheckCID: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 診断内容のオブジェクト一覧
   */
  checks: state => state.checks,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid ドキュメントID
   * @return {Object} 回答結果のオブジェクト
   */
  check: state => cid => state.checks[cid] ? state.checks[cid] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 脳タイプ診断のドキュメントID
   */
  brainCheckCID: state => state.brainCheckCID
}

const mutations = {
  /**
   * 診断結果の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid ドキュメントID
   * @param {Obcjet} payload.check 格納する診断結果のドキュメント
   */
  setCheck: (state, payload) => {
    state.checks = Object.assign({}, state.checks, { [payload.cid]: payload.check })
  },
  /**
   * 診断結果の更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid ドキュメントID
   * @param {Obcjet} payload.params 更新する値
   */
  updateCheck: (state, payload) => {
    Object.keys(payload.params).forEach(key => {
      state.checks[payload.cid][key] = payload.params[key]
    })
  },
  /**
   * 診断結果の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid ドキュメントID
   */
  setBrainCheckCID: (state, cid) => {
    state.brainCheckCID = cid
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 診断情報の作成
   * @param {Object} params 診断情報の値
   * @return {String} ドキュメントID
   */
  addCheck: async ({ commit }, params) => {
    try {
      const doc = await addDoc(collection(db, 'checks'), params)

      const cid = doc.id
      commit('setCheck', { cid: cid, check: params })
      return cid
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 診断内容の取得
   */
  getChecks: async ({ commit }) => {
    try {
      const snapshot = await getDocs(collection(db, 'checks'))

      snapshot.forEach(doc => {
        if (doc.data().name === '脳タイプ診断') commit('setBrainCheckCID', doc.id)
        commit('setCheck', { cid: doc.id, check: doc.data() })
      })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 診断内容の更新
   * @param {String} cid 診断情報のドキュメントID
   * @param {Object} params 更新内容
   */
  updateCheck: async ({ commit }, { cid, params }) => {
    try {
      const docRef = doc(db, 'checks', cid)
      await updateDoc(docRef, params)

      commit('updateCheck', { cid: cid, params: params })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
