import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'


const app = createApp(App)
app.config.devtools = process.env.NODE_ENV !== 'production'

app.use(router)
app.use(store)
app.use(vuetify)

app.mount('#app')
