import { db } from '@/firebase'
import { collection, addDoc } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 集計情報の追加
   * @param {String} qid 質問情報のドキュメントID
   * @param {Number} answersLength 回答候補の配列の長さ
   */
  addSummary: async ({ commit }, { qid, answersLength }) => {
    try {
      await addDoc(collection(db, 'question_summaries'), {
        qid: qid,
        summaries: [...Array(answersLength)].fill(0),
        createdAt: new Date(),
        updatedAt: new Date()
      })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
