import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import router from '@/router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * 画像のアップロード
   * @param {Object} payload 引数の情報
   * @param {Blob} payload.blob アップロードする画像のBlob
   * @param {String} payload.path アップロードする画像の格納先
   * @return {Object} url: アップロード後のURL
   */
  uploadImage: async ({ commit }, payload) => {
    try {
      const storage = getStorage()
      const imageRef = ref(storage, payload.path)

      // メタデータ
      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: 'image/' + payload.path.match(/(.*)(?:\.([^.]+$))/)[2]
      }
      await uploadBytes(imageRef, payload.blob, metadata)

      const url = await getDownloadURL(imageRef)
      return url
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 画像の削除
   * @param {String} url 削除したい画像のURL
   */
  deleteImage: async ({ commit }, url) => {
    try {
      const storage = getStorage()
      const imageRef = ref(storage, url)
      await deleteObject(imageRef)
    } catch {
      // 画像の削除に失敗した場合もフロントの処理には影響を与えないので、処理はしない
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
