import { initializeApp } from 'firebase/app'
import { getAuth, FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider, browserLocalPersistence } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
}

// 利用するプロバイダ一覧
const provider = {
  google: new GoogleAuthProvider(),
  facebook: new FacebookAuthProvider(),
  twitter: new TwitterAuthProvider()
}

const firebaseApp = initializeApp(config)
const auth = getAuth(firebaseApp)
auth.setPersistence(browserLocalPersistence)
const db = getFirestore(firebaseApp)

export { firebaseApp, db, provider, auth }
