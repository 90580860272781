import { db } from '@/firebase'
import { collection, doc, addDoc, getDocs, updateDoc } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 脳タイプ別の診断情報の一覧
    // { cid: [], cid: [], ... }
    brains: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object[]} 指定診断の脳タイプ別の診断情報
   */
  brains: state => cid => state.brains[cid] || []
}

const mutations = {
  /**
   * cidに紐づく全ての脳タイプ別診断情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid 診断内容のドキュメントID
   * @param {Object} payload.brains 脳タイプ別診断情報
   */
  setBrains: (state, payload) => {
    state.brains = Object.assign({}, state.brains, { [payload.cid]: payload.brains })
  },
  /**
   * 作成した脳タイプ別診断情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断内容のドキュメントID
   * @param {Object} params 追加した値（bidは付与しておく）
   */
  addBrain: (state, { cid, params }) => {
    state.brains[cid] = []
    state.brains[cid].push(params)
  },
  /**
   * 脳タイプ別診断情報の更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断情報のドキュメントID
   * @param {String} bid 脳タイプ別診断情報のドキュメントID
   * @param {Obcjet} params 更新する値
   */
  updateBrain: (state, { cid, bid, params }) => {
    // 更新対象の格納順を探す
    const index = state.brains[cid].findIndex(brain => brain.bid === bid)

    // 値の更新
    Object.keys(params).forEach(key => {
      state.brains[cid][index][key] = params[key]
    })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定したcidに紐づく脳タイプ別診断情報の取得
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object[]} 脳タイプ別診断情報の一覧
   */
  getBrains: async ({ commit }, cid) => {
    try {
      const snapshot = await getDocs(collection(db, 'checks', cid, 'brains'))

      const brains = []
      snapshot.forEach(doc => {
        brains.push(Object.assign(doc.data(), { bid: doc.id }))
      })

      commit('setBrains', { cid: cid, brains: brains })
      return brains
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 指定したcidに紐づく脳タイプ別診断情報の取得
   * @param {String} cid 診断内容のドキュメントID
   * @param {Obcjet} params 作成する値
   */
  addBrain: async ({ commit }, { cid, params }) => {
    try {
      const doc = await addDoc(collection(db, 'checks', cid, 'brains'), params)

      commit('addBrain', { cid: cid, params: Object.assign({ bid: doc.id }, params) })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 指定したcidに紐づく脳タイプ別診断情報の取得
   * @param {String} cid 診断内容のドキュメントID
   * @param {String} bid 脳タイプ別診断情報のドキュメントID
   * @param {Obcjet} params 作成する値
   */
  updateBrain: async ({ commit }, { cid, bid, params }) => {
    try {
      const docRef = doc(db, 'checks', cid, 'brains', bid)
      await updateDoc(docRef, params)

      commit('updateBrain', { cid: cid, bid: bid, params: params })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
