import { createStore } from 'vuex'

import auth from '@/store/modules/auth'
import cloudstorage from '@/store/modules/cloudstorage'
import functions from '@/store/modules/functions'
import users from '@/store/modules/users'
import follows from '@/store/modules/follows'
import checks from '@/store/modules/checks'
import maslows from '@/store/modules/checks/maslows'
import brains from '@/store/modules/checks/brains'
import questions from '@/store/modules/questions'
import totalQuestions from '@/store/modules/totalQuestions'
import questionCategories from '@/store/modules/questionCategories'
import questionSummaries from '@/store/modules/questionSummaries'
import results from '@/store/modules/results'
import answers from '@/store/modules/results/answers'
import totalResults from '@/store/modules/totalResults'
import notification from '@/store/modules/notification'
import maintenances from '@/store/modules/maintenances'

const modules = { auth, cloudstorage, functions, users, follows, checks, maslows, brains, questions, totalQuestions, questionCategories, questionSummaries, results, answers, totalResults, notification, maintenances }

export default createStore({
  modules: modules,
  state: {
    isProcessing: true,
    isSubmitting: false,
    isAuthProcessing: true,
    redirectPath: null,
    queryParameter: '',
    telop: {
      show: false,
      msg: '',
      type: ''
    },
    MAX_LENGTH: {
      CHECK_ABOUT: {
        name: 30,
        description: 400
      },
      CHECK_QUESTION: {
        question: 100
      },
      CHECK_MASLOW: {
        description: 400
      },
      CHECK_BRAIN: {
        title: 30,
        description: 400
      },
      NOTIFICATION: {
        message: 40
      },
      QUESTION: {
        images: 3,
        question: 100,
        caption: 10
      },
      QUESTION_CATEGORIES: {
        name: 30
      }
    }
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 処理中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} フォーム等の送信中かどうか
     */
    isSubmitting: state => state.isSubmitting,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURLのパラメータ
     */
    queryParameter: state => state.queryParameter,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet} プロフィールの格納できる文字数の最大値
     */
    MAX_LENGTH: state => state.MAX_LENGTH
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 処理中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
      if (state.isSubmitting) state.isSubmitting = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSubmitting フォーム等の送信中かどうか
     */
    setSubmitting: (state, isSubmitting) => {
      state.isSubmitting = isSubmitting
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} queryParameter アクセスされたURLのパラメータ
     */
    setQueryParameter: (state, queryParameter) => {
      state.queryParameter = queryParameter
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    }
  },
  actions: {
    /**
     * 全モジュールのstoreをリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        if (Object.keys(modules[key].state).length !== 0) commit(key + '/resetState')
      })
    }
  }
})
