import { db } from '@/firebase'
import { collection, doc, limit, orderBy, query, addDoc, getDocs, updateDoc } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // お知らせ情報
    // { message: , startAt: , endAt: , createdAt: , updatedAt:  }
    notification: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新のお知らせ情報
   */
  notification: state => state.notification
}

const mutations = {
  /**
   * お知らせ情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} notification お知らせ情報のオブジェクト
   */
  setNotification: (state, notification) => {
    if (new Date() <= notification.endAt.toDate()) {
      state.notification = notification
    }
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * お知らせ情報の取得
   */
  getNotification: async ({ commit }) => {
    try {
      const q = query(collection(db, 'notification'), orderBy('endAt', 'desc'), limit(1))
      const snapshot = await getDocs(q)
      snapshot.forEach(doc => commit('setNotification', Object.assign({ nid: doc.id }, doc.data())))
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * お知らせ情報の作成
   * @param {Object} payload お知らせ情報
   */
  createNotification: async ({ commit }, payload) => {
    try {
      await addDoc(collection(db, 'notification'), payload)
      commit('resetState')
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * お知らせ情報の更新
   * @param {Object} payload お知らせ情報
   * @param {String} payload.nid お知らせID
   * @param {Object} payload.params お知らせ情報
   * @return {void}
   */
  updateNotification: async ({ commit }, payload) => {
    try {
      const docRef = doc(db, 'notification', payload.nid)
      await updateDoc(docRef, payload.params)
      commit('resetState')
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
