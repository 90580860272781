import { db } from '@/firebase'
import { collection, limit, orderBy, query, getDocs } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 診断結果の総数
    // { count: <診断結果の総数>,createdAt: <入稿日時>,updatedAt: <更新日時>}
    totalResults: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 診断結果の総数
   */
  totalResults: state => state.totalResults
}

const mutations = {
  /**
   * 診断結果の総数情報をstateにセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} totalResults 診断結果の総数
   */
  setTotalResults: (state, totalResults) => {
    state.totalResults = Object.assign({}, totalResults)
  }
}

const actions = {
  /**
   * 診断結果の総数の取得
   * @param {String} uid ユーザーID
   */
  getTotalResults: async ({ commit }) => {
    try {
      const q = query(collection(db, 'total_results'), orderBy('updatedAt', 'desc'), limit(1))
      const snapshot = await getDocs(q)

      const total = snapshot.empty ? null : snapshot.docs[0].data()
      if (!!total) commit('setTotalResults', { count: total.count, createdAt: total.createdAt, updatedAt: total.updatedAt })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
