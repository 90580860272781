import { db } from '@/firebase'
import { collection, doc, getDoc, getDocs, updateDoc, query, where } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全ユーザーのオブジェクト一覧
    // { uid: {}, uid: {}, ... }
    users: {},
    // ユーザー情報を全権取得済みかどうか
    isGotAllUsers: false,
    // アクティブユーザーのオブジェクト一覧
    activeUsers: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全てのユーザー情報を取得済みかどうか
   */
  isGotAllUsers: state => state.isGotAllUsers,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} ユーザー情報の一覧
   */
  users: state => state.users,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} アクティブユーザー情報の一覧
   */
  activeUsers: state => state.activeUsers,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  user: state => uid => state.users[uid] ? state.users[uid] : null
}

const mutations = {
  /**
   * ユーザー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.user ユーザーのオブジェクト
   */
  setUser: (state, payload) => {
    state.users[payload.uid] = payload.user
  },
  /**
   * アクティブユーザー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.user ユーザーのオブジェクト
   */
  setActiveUser: (state, payload) => {
    state.activeUsers[payload.uid] = payload.user
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * ユーザー情報を取得
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  async getUser ({ commit }, uid) {
    try {
      const docRef = doc(db, 'users', uid)
      const docSnap = await getDoc(docRef)

      const user = docSnap.exists() ? docSnap.data() : null
      if (user) commit('setUser', { uid: uid, user: user })
      return user
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 全てのユーザー情報を取得
   */
  async getUsers ({ commit }) {
    try {
      const snapshot = await getDocs(collection(db, 'users'))

      snapshot.forEach(doc => {
        commit('setUser', { uid: doc.id, user: doc.data() })
      })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 全てのアクティブユーザー情報を取得
   */
  async getActiveUsers ({ commit }) {
    try {
      const q = query(collection(db, 'users'), where('isDeleted', '!=', true))
      const snapshot = await getDocs(q)

      snapshot.forEach(doc => {
        commit('setActiveUser', { uid: doc.id, user: doc.data() })
      })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 管理者一覧の取得
   * @return {Object} 管理者一覧 { uid: {}, uid: {}, ... }
   */
  async getAdmins ({ commit, rootGetters }) {
    try {
      const q = query(collection(db, 'users'), where('authority', '==', 'admin'), where('isDeleted', '==', false))
      const snapshot = await getDocs(q)

      const admins = {}

      // 自分自身は除くため自分のuidを取得しておく
      const uid = rootGetters['auth/uid']

      // 管理者情報を返り値に合わせてフォーマット
      snapshot.forEach(doc => {
        if (uid !== doc.id) admins[doc.id] = doc.data()
      })
      return admins
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * ユーザー情報の更新
   * @param {String} uid 更新するユーザーのID
   * @param {Object} params 更新したいユーザー情報のパラメータ
   */
  async updateUser ({ commit }, { uid, params }) {
    try {
      const docRef = doc(db, 'users', uid)
      await updateDoc(docRef, params)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
