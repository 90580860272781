import { db } from '@/firebase'
import { collection, getDocs, deleteDoc, query, where } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    //  診断結果の一覧 { cid: [{}, ...], ... }
    results: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 診断結果の一覧
   */
  results: state => state.results,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 取得したい診断のドキュメントID
   * @return {Object[]} 診断結果の一覧
   */
  resultsCID: state => cid => state.results[cid] || []
}

const mutations = {
  /**
   * 診断結果の一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {Object} payload.cid 診断のドキュメントID
   * @param {Object} payload.results 診断結果の一覧
   */
  setResults: (state, payload) => {
    state.results = Object.assign({}, state.results, { [payload.cid]: payload.results })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 診断結果を全て取得
   * @return {Object[]} 診断結果の一覧 [ {}, ... ]
   */
  async getResults ({ commit }) {
    try {
      const snapshot = await getDocs(collection(db, 'results'))

      const results = []
      snapshot.forEach(doc => {
        results.push(Object.assign(doc.data(), { rid: doc.id }))
      })
      return results
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 指定診断の診断結果を全て取得
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object[]} 診断結果の一覧 [ {}, ... ]
   */
  async getResultsCID ({ commit }, cid) {
    try {
      const q = query(collection(db, 'results'), where('cid', '==', cid))
      const snapshot = await getDocs(q)

      const results = []
      snapshot.forEach(doc => {
        results.push(Object.assign(doc.data(), { rid: doc.id }))
      })
      commit('setResults', { cid: cid, results: results })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 診断結果の削除
   * @param {String} rid 診断結果のドキュメントID
   */
  async deleteResult ({ commit }, rid) {
    try {
      const docRef = doc(db, 'results', rid)
      await deleteDoc(docRef)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
