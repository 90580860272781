import { db } from '@/firebase'
import { collection, getDocs } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全友達登録情報のオブジェクト一覧
    // { uid: [], uid: [], ... }
    follows: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 友達登録情報
   */
  follows: state => state.follows || null
}

const mutations = {
  /**
   * 友達情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} follows 友達情報
   */
  setFollows: (state, follows) => {
    state.follows = follows
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 全ての友達登録情報を取得
   * @return {Object} 全ての友達登録情報
   */
  async getFollows ({ commit }) {
    try {
      const followsCollection = collection(db, 'follows')
      const snapshot = await getDocs(followsCollection)

      const follows = {}
      snapshot.forEach(doc => {
        if (!follows[doc.data().uid]) follows[doc.data().uid] = []
        follows[doc.data().uid].push(Object.assign(doc.data(), { fid: doc.id }))
      })
      commit('setFollows', follows)
      return follows
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
