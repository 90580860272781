import { db } from '@/firebase'
import { collection, doc, orderBy, query, addDoc, getDocs, updateDoc } from 'firebase/firestore'

import router from '@/router'

const getDefaultState = () => {
  return {
    // 診断の質問情報の一覧
    // { cid: [], cid: [], ... }
    maslows: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object[]} 指定診断のマズローの解説情報
   */
  maslows: state => cid => state.maslows[cid] || []
}

const mutations = {
  /**
   * cidに紐づく全てのマズロー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid 診断内容のドキュメントID
   * @param {Object} payload.maslows マズロー情報の一覧
   */
  setMaslows: (state, payload) => {
    state.maslows = Object.assign({}, state.maslows, { [payload.cid]: payload.maslows })
  },
  /**
   * 作成したマズロー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断内容のドキュメントID
   * @param {Object} params 追加した値（mlidは付与しておく）
   */
  addMaslow: (state, { cid, params }) => {
    state.maslows[cid] = []
    state.maslows[cid].push(params)
  },
  /**
   * マズロー情報の更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断情報のドキュメントID
   * @param {String} mlid マズロー情報のドキュメントID
   * @param {Obcjet} params 更新する値
   */
  updateMaslow: (state, { cid, mlid, params }) => {
    // 更新対象の格納順を探す
    const index = state.maslows[cid].findIndex(maslow => maslow.mlid === mlid)

    // 値の更新
    Object.keys(params).forEach(key => {
      state.maslows[cid][index][key] = params[key]
    })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定したcidに紐づくマズロー情報の作成
   * @param {String} cid 診断内容のドキュメントID
   * @param {Object} params 作成するマズロー情報
   */
  addMaslow: async ({ commit }, { cid, params }) => {
    try {
      const doc = await addDoc(collection(db, 'checks', cid, 'maslows'), params)

      commit('addMaslow', { cid: cid, params: Object.assign({ mlid: doc.id }, params) })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 指定したcidに紐づくマズロー情報の取得
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object[]} マズロー情報の一覧
   */
  getMaslows: async ({ commit }, cid) => {
    try {
      const q = query(collection(db, 'checks', cid, 'maslows' ), orderBy('step'))
      const snapshot = await getDocs(q)

      const maslows = []
      snapshot.forEach(doc => {
        maslows.push(Object.assign(doc.data(), { mlid: doc.id }))
      })
      commit('setMaslows', { cid: cid, maslows: maslows })

      return maslows
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * マズロー情報の更新
   * @param {String} cid 診断内容のドキュメントID
   * @param {String} mlid マズロー情報のドキュメントID
   * @param {Object} params 更新するマズロー情報
   */
  updateMaslow: async ({ commit }, { cid, mlid, params }) => {
    try {
      const docRef = doc(db, 'checks', cid, 'maslows', mlid)
      await updateDoc(docRef, params)

      commit('updateMaslow', { cid: cid, mlid: mlid, params: params })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
