import { auth as firebaseAuth, provider } from '@/firebase'
import { signInWithRedirect, signInWithCustomToken, onAuthStateChanged, signOut } from 'firebase/auth'
import router from '@/router'

// LINE認証時に利用するローカルストレージのkey名
const lineLocalStorageKey = {
  isLINEAuthProcessing: 'adminLineAuthProcessing'
}

const getDefaultState = () => {
  return {
    uid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
    * ユーザーIDをstateにセット
    * @param {Object} state 暗黙的に受け取るstate
    * @param {String} uid ユーザーID
    */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
    * ユーザ情報が更新時、uidを更新する
    */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    onAuthStateChanged(firebaseAuth, async auth => {
      const transitionPath = rootGetters.redirectPath
      const isLINEAuthProcessing = localStorage.getItem(lineLocalStorageKey.isLINEAuthProcessing)
      const isRedirectAuthLine = isLINEAuthProcessing && transitionPath && transitionPath.includes('/auth/line')

      if (isRedirectAuthLine) {
        const queryParameter = rootGetters.queryParameter

        const query = {}
        queryParameter.substr(1).split('&').forEach(params => {
          const param = params.split('=')
          if (param[0]) query[param[0]] = decodeURIComponent(param[1])
        })

        commit('setAuthProcessing', false, { root: true })
        router.replace({ path: transitionPath, query: query })
        return
      }

      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      // セッションの内容を確認
      const isLoggedin = sessionStorage.getItem('isLoggedin')
      sessionStorage.removeItem('isLoggedin')

      if (auth) {
        // ユーザー情報の取得
        const user = await dispatch('users/getUser', auth.uid, { root: true })

        // 権限の確認
        // ユーザーが存在しない or 権限がない場合はログアウトして警告
        if (!user || user.authority !== 'admin') {
          await dispatch('signout')
          commit('setTelop', { show: true, msg: 'アクセス権限がありません', type: 'error' }, { root: true })
          commit('setRedirectURL', '/login', { root: true })
        } else if (isLoggedin) {
          // ログインの場合は、テロップを表示
          commit('setTelop', { show: true, msg: 'ログインしました', type: 'success' }, { root: true })
        }
      }

      commit('setAuthProcessing', false, { root: true })

      // 初回アクセスの場合にリダイレクトさせ、routerを反応させる
      const redirectPath = rootGetters.redirectPath
      router.replace({ path: redirectPath, query: { auth: new Date().getTime() } })
    })
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook, twitter
   */
  async signin ({ commit }, sns) {
    try {
      await signInWithRedirect(firebaseAuth, provider[sns])
    } catch {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * カスタムトークンを利用してsigninを行う
   * @param {String} token firebase認証用カスタムトークン
   */
  signInWithCustomToken: async ({ commit }, token) => {
    try {
      await signInWithCustomToken(firebaseAuth, token)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * サインアウト
   * @return {Object} status：成功の有無（success or error）、error：エラーコード（エラー時のみ）
   */
  async signout ({ commit, dispatch }) {
    try {
      await signOut(firebaseAuth)

      // storeの不要な情報をリセットする
      dispatch('resetState', null, { root: true })

      return { status: 'success' }
    } catch (error) {
      return { status: 'error', error: error.code }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
