<template>
  <div
    v-if="!isProcessing"
    class="home">
    <div
      v-for="item in items"
      :key="item.title"
      class="home__menu">
      <home-menu :item="item" />
    </div>
  </div>
</template>

<script>
import HomeMenu from '@/components/common/menu.vue'

export default {
  name: 'HomeView',
  components: { HomeMenu },
  data () {
    return {
      items: [
        { title: '管理者', content: '管理者の追加、削除ができます', to: 'AdminView' },
        { title: '診断の追加・編集', content: '新しい診断を追加したり、編集することができます。', to: 'CheckView' },
        { title: '質問の追加・編集', content: '新しい質問を追加したり、編集することができます。', to: 'QuestionsView' },
        { title: 'データの閲覧', content: 'ユーザーデータ等の閲覧ができます。', to: 'DataView' },
        { title: 'お知らせ', content: 'お知らせを投稿/編集できます。', to: 'NotificationView' },
        { title: 'メール送信', content: 'ユーザーにメールを送信できます。', to: 'MailView' },
        { title: 'メンテナンス', content: 'メンテナンスの開始・終了ができます。', to: 'MaintenanceView' },
        { title: 'CSV出力', content: 'データをCSV形式で出力することができます。', to: 'CsvView' },
        { title: 'データリセット **触らない**', content: '既存データのリセット処理ができます。', to: 'ResetView' },
        { title: '質問機能リリースに向けたDB対応（触らない）', content: 'DBを質問機能リリースに向け修正します。触らないよう注意してください。', to: 'DbView' },
        { title: 'ログアウト', content: 'ログアウトの実行', func: () => this.signout() }
      ]
    }
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  methods: {
    /**
     * サインアウト
     */
    signout () {
      this.$store.commit('setSubmitting', true)
      this.$store.dispatch('auth/signout').then(result => {
        // サインアウト成功時はログイン画面へ、失敗場合はエラー画面へ
        if (result.status === 'success') {
          this.$store.commit('setTelop', { show: true, msg: 'ログアウトしました', type: 'success' })
          this.$router.push({ name: 'LoginView' })
        } else {
          this.$router.push({ name: 'ErrorView' })
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.home {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
}
</style>
