<template>
  <div class="common-processing">
    <v-progress-circular
      indeterminate
      :width="5"
      :size="50" />
  </div>
</template>

<script>
export default {
  name: 'CommonProcessingComponent'
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.common-processing {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $processing_zindex;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $white_color;
  .v-progress-circular {
    color: $green_color;
  }
}
</style>
