import { db } from '@/firebase'
import { doc, collection, addDoc, getDocs, updateDoc, increment } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 質問総数情報のID
    tqid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} 質問総数のID
   */
  tqid: state => state.tqid || null
}

const mutations = {
  /**
   * 総数情報のIDをセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} tqid 質問総数情報のID
   */
  setTqid: (state, tqid) => {
    state.tqid = tqid
  },
  /**
   * stateのリセットを行う
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * 質問総数の追加
   * Db.vueで使用（使用後、削除対象）
   * @param {Object} params 追加する値
   */
  async addTotalQuestion ({ commit }, params) {
    try {
      await addDoc(collection(db, 'total_questions'), params)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 質問総数の取得
   */
  async getTotal ({ commit }) {
    try {
      const snapshot = await getDocs(collection(db, 'total_questions'))

      const tqid = snapshot.docs[0].id
      commit('setTqid', tqid)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 質問総数の更新
   * @param {String} tqid 質問総数のID
   */
  async incrementTotal ({ commit }, tqid) {
    try {
      const docRef = doc(db, 'total_questions', tqid)

      await updateDoc(docRef, {
        count: increment(1),
        updatedAt: new Date()
      })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
