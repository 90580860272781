<template>
  <header
    v-if="isDisplay"
    class="common-header">
    <v-btn
      v-if="type.includes('back')"
      icon="mdi-arrow-left"
      variant="text"
      class="common-header__btn"
      @click="doBack()" />
    <v-img
      v-if="type.includes('logo')"
      class="common-header__logo"
      :src="require('@/assets/img/logo.png')"
      cover />
    <p
      v-if="type.includes('title')"
      class="common-header__title">
      {{ title }}
    </p>
  </header>
</template>


<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'AuthLineView',
  'LoginView',
  'ErrorView',
  'NotfoundView'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  HomeView: {
    type: ['title'],
    title: 'Braincheck 管理者ページ'
  },
  AdminView: {
    type: ['back', 'title'],
    title: '管理者'
  },
  AdminAddView: {
    type: ['back', 'title'],
    title: 'Braincheck管理者権限の付与'
  },
  AdminDeleteView: {
    type: ['back', 'title'],
    title: 'Braincheck管理者権限の削除'
  },
  CheckView: {
    type: ['back', 'title'],
    title: '診断の追加・編集'
  },
  CheckEditView: {
    type: ['back', 'title'],
    title: '診断の編集'
  },
  QuestionsView: {
    type: ['back', 'title'],
    title: '質問の追加・編集'
  },
  QuestionsView: {
    type: ['back', 'title'],
    title: '質問の追加・編集'
  },
  QuestionsListView: {
    type: ['back', 'title'],
    title: '質問の編集'
  },
  QuestionsAddView: {
    type: ['back', 'title'],
    title: '質問の追加'
  },
  QuestionsEditView: {
    type: ['back', 'title'],
    title: '質問の編集'
  },
  QuestionsCategoryAddView: {
    type: ['back', 'title'],
    title: '質問のカテゴリー追加'
  },
  DataView: {
    type: ['back', 'title'],
    title: 'データの閲覧'
  },
  DataFriendView: {
    type: ['back', 'title'],
    title: '友達登録数'
  },
  DataTypeView: {
    type: ['back', 'title'],
    title: '脳タイプ数'
  },
  DataQuestionView: {
    type: ['back', 'title'],
    title: '各診断の回答率'
  },
  DataDesireView: {
    type: ['back', 'title'],
    title: '脳タイプと欲求'
  },
  NotificationView: {
    type: ['back', 'title'],
    title: 'お知らせ'
  },
  NotificationSettingView: {
    type: ['back', 'title'],
    title: 'お知らせの設定'
  },
  MailView: {
    type: ['back', 'title'],
    title: 'メール送信'
  },
  MailAllView: {
    type: ['back', 'title'],
    title: '全ユーザーにメール送信'
  },
  ResetView: {
    type: ['back', 'title'],
    title: 'データリセット'
  },
  ResetResultView: {
    type: ['back', 'title'],
    title: 'UIDが紐づいてない診断結果の削除'
  },
  ResetSummaryView: {
    type: ['back', 'title'],
    title: '質問の集計結果の再計算'
  },
  MaintenanceView: {
    type: ['back', 'title'],
    title: 'メンテナンス'
  },
  MaintenanceStartView: {
    type: ['back', 'title'],
    title: 'メンテナンスの開始'
  },
  MaintenanceEndView: {
    type: ['back', 'title'],
    title: 'メンテナンスの終了'
  },
  CsvView: {
    type: ['back', 'title'],
    title: 'CSV出力'
  },
  DbView: {
    type: ['back', 'title'],
    title: '質問機能リリースに向けたDB対応'
  }
}

export default {
  name: 'CommonHeaderComponent',
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.pageName && HEADER_DETAIL[this.pageName].title ? HEADER_DETAIL[this.pageName].title : ''
    },
    /**
     * @return {String} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : ''
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はホームへ遷移させる
     */
    doBack () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'HomeView' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.common-header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $white_normal_color;
  transform: translate(-50%, 0);
  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    font-size: calc(2rem / 1.5) !important; // iconのfont-sizeが1.5emかけるので割っておく
    &.v-btn--icon.v-btn--density-default {
      width: 20px;
      height: 20px;
    }
  }
  &__logo {
    width: 120px;
    max-height: calc(#{$header_height} - 10px);
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
</style>
