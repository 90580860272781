import { firebaseApp } from '@/firebase'
import { getFunctions, httpsCallable } from 'firebase/functions'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const functions = getFunctions(firebaseApp, REGION)

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * メールアドレスからユーザー情報を取得する
   * @param {String} email メールアドレス
   * @return {Object} { email: メールアドレス, uid: ユーザーID, user: ユーザー情報 }
   */
  async getUserByEmail ({ commit }, email) {
    try {
      const get = httpsCallable(functions, 'getUserByEmail')
      const results = await get({ email: email })
      return results.data
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 指定ユーザーにメールを一斉送信
   * @param {String} payload メール情報
   * @param {String} payload.subject メールのタイトル
   * @param {String} payload.content メールの本文（改行コード含む）
   * @param {String} payload.to 送信先 [ all: auth認証がある全員 / users: auth認証かつusersにデータがある全員 ]
   */
  async sendEmailAll ({ commit }, payload) {
    try {
      const send = httpsCallable(functions, 'sendEmailAll')
      await send({
        subject: payload.subject,
        content: payload.content,
        to: payload.to
      })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * メンテナンス用のrulesに変更してDBアクセスを停止
   * @return {void}
   */
  async setMaintenanceRules ({ commit }) {
    try {
      const setMaintenanceRules = httpsCallable(functions, 'setMaintenanceRules')
      await setMaintenanceRules()
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * カスタムクレイムのセット
   * @param {Object} payload 引数
   * @param {String} paylod.uid 設定するユーザーのid
   * @param {Object} paylod.params 設定する値
   * @return {void}
   */
  setCustomClaims ({ commit }, payload) {
    return new Promise(resolve => {
      const setCustomClaims = httpsCallable(functions, 'setCustomClaims')
      setCustomClaims({ uid: payload.uid, params: payload.params }).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'ErrorView' })
      })
    })
  },
  /**
   * LINE認証を行う
   * @param {String} code OIDC認証コード
   * @param {String} nonce 認証改竄防止用のID
   * @return {Object} カスタムトークンを含むJSONオブジェクト { token: <カスタムトークンの値>}
   */
  async authLine ({ commit }, { code, nonce }) {
    const authLine = httpsCallable(functions, 'authLine')
    const authLineResponse = await authLine({ code, nonce, isAdmin: true })
    return authLineResponse.data
  },
  /**
   * 回答履歴データを取得しCSVデータとしてダウンロードする
   */
  async getAnswerHistoryCsv ({ commit }) {
    try {
      const answerHistoryCsv = httpsCallable(functions, 'getAnswerHistoryCsv')
      const answerHistoryCsvResponse = await answerHistoryCsv()

      const json2csv = require('json2csv').parse
      const csvData = json2csv(answerHistoryCsvResponse.data.results)

      // CSVデータのダウンロード
      const downloadCsv = csvData => {
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', 'AnswerHistoryCsv.csv')
        link.click()
      }
      downloadCsv(csvData)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 性別情報の付与
   */
  async addSexData () {
    try {
      const addSexData = httpsCallable(functions, 'addSexData')
      await addSexData()
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 質問の集計結果を再集計
   */
  async analyzingAllQuestionResults ({ commit }) {
    try {
      const analyzingAllQuestionResults = httpsCallable(functions, 'analyzingAllQuestionResults')
      await analyzingAllQuestionResults()
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
