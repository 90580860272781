import { db } from '@/firebase'
import { collection, doc, limit, orderBy, query, addDoc, getDocs, updateDoc } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 最新のメンテナンス情報
    maintenance: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新のメンテナンス情報
   */
  maintenance: state => state.maintenance
}

const mutations = {
  /**
   * メンテナンス情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} maintenance メンテナンス情報のオブジェクト
   */
  setMaintenance: (state, maintenance) => {
    state.maintenance = maintenance
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * メンテナンス情報の作成
   * @param {Object} payload 引数のメンテナンス情報
   * @return {void}
   */
  createMaintenance ({ commit }, payload) {
    return new Promise(resolve => {
      addDoc(collection(db, 'maintenances'), payload)
        .then(doc => {
          commit('setMaintenance', Object.assign({ mid: doc.id }, payload ))
          resolve()
        })
        .catch(() => {
          router.push({ name: 'ErrorView' })
        })
    })
  },
  /**
   * メンテナンス情報の取得
   * @return {void}
   */
  getMaintenance ({ commit }) {
    return new Promise(resolve => {
      getDocs(query(collection(db, 'maintenances'), orderBy('createdAt', 'desc'), limit(1)))
        .then(snapshot => {
          snapshot.forEach(doc => commit('setMaintenance', Object.assign({ mid: doc.id }, doc.data())))
          resolve()
        })
        .catch(() => {
          router.push({ name: 'ErrorView' })
        })
    })
  },
  /**
   * メンテナンス情報の更新
   * @param {Object} payload 引数の情報
   * @param {String} payload.mid メンテナンスID
   * @param {Object} payload.params メンテナンス情報
   * @return {void}
   */
  updateMaintenance ({ commit }, payload) {
    return new Promise(resolve => {
      updateDoc(doc(db, 'maintenances', payload.mid), payload.params)
        .then(() => {
          commit('setMaintenance', Object.assign({ mid: payload.mid }, payload.params ))
          resolve()
        })
        .catch(() => {
          router.push({ name: 'ErrorView' })
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
