import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { auth as firebaseAuth } from '@/firebase'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'HomeView',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'LoginView',
      component: loadComponent('Login.vue')
    },
    {
      path: '/auth/line',
      name: 'AuthLineView',
      component: loadComponent('AuthLine.vue')
    },

    {
      path: '/admin',
      name: 'AdminView',
      component: loadComponent('Admin.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/add',
      name: 'AdminAddView',
      component: loadComponent('AdminAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/delete',
      name: 'AdminDeleteView',
      component: loadComponent('AdminDelete.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/check',
      name: 'CheckView',
      component: loadComponent('Check.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/check/edit/:cid',
      name: 'CheckEditView',
      component: loadComponent('CheckEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/questions',
      name: 'QuestionsView',
      component: loadComponent('Questions.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/questions/list',
      name: 'QuestionsListView',
      component: loadComponent('QuestionsList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/questions/add',
      name: 'QuestionsAddView',
      component: loadComponent('QuestionsAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/questions/:qid/edit',
      name: 'QuestionsEditView',
      component: loadComponent('QuestionsEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/questions/category/add',
      name: 'QuestionsCategoryAddView',
      component: loadComponent('QuestionsCategoryAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/data',
      name: 'DataView',
      component: loadComponent('Data.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/data/type',
      name: 'DataTypeView',
      component: loadComponent('DataType.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/data/friend',
      name: 'DataFriendView',
      component: loadComponent('DataFriend.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/data/question',
      name: 'DataQuestionView',
      component: loadComponent('DataQuestion.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/data/desire',
      name: 'DataDesireView',
      component: loadComponent('DataDesire.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/notification',
      name: 'NotificationView',
      component: loadComponent('Notification.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/notification/setting',
      name: 'NotificationSettingView',
      component: loadComponent('NotificationSetting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/mail',
      name: 'MailView',
      component: loadComponent('Mail.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/mail/all',
      name: 'MailAllView',
      component: loadComponent('MailAll.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/reset',
      name: 'ResetView',
      component: loadComponent('Reset.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/reset/result',
      name: 'ResetResultView',
      component: loadComponent('ResetResult.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/reset/summary',
      name: 'ResetSummaryView',
      component: loadComponent('ResetSummary.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance',
      name: 'MaintenanceView',
      component: loadComponent('Maintenance.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance/start',
      name: 'MaintenanceStartView',
      component: loadComponent('MaintenanceStart.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance/end',
      name: 'MaintenanceEndView',
      component: loadComponent('MaintenanceEnd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/csv',
      name: 'CsvView',
      component: loadComponent('Csv.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/db',
      name: 'DbView',
      component: loadComponent('Db.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'ErrorView',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'NotfoundView',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/**',
      redirect: { name: 'NotfoundView' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 別画面に遷移する時、processingを表示状態にする
  if (to.name !== from.name) store.commit('setProcessing', true)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebaseAuth.currentUser
  const uid = auth ? auth.uid : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    if (autoTransition) {
      next()
    } else {
      // Auth処理中の場合はパラメータ情報が失われないようstoreに保存し遷移をキャンセルさせる
      store.commit('setQueryParameter', to.query ? `?${Object.keys(to.query).map(key => `${key}=${to.query[key]}`).join('&')}` : '')
      next(false)
    }
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'LoginView' }) : next()
  } else {
    // ログイン済みの場合
    requiresAuth ? next() : next({ name: 'HomeView' })
  }
})

export default router
