import { db } from '@/firebase'
import { collection, getDocs, addDoc } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 質問カテゴリー情報のオブジェクト一覧
    // { qcid: {}, qcid: {}, ... }
    questionCategories: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 質問カテゴリー情報
   */
  questionCategories: state => state.questionCategories
}

const mutations = {
  /**
   * 質問カテゴリー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} questionCategories 質問カテゴリー情報のオブジェクト
   */
  setQuestionCategories: (state, questionCategories) => {
    state.questionCategories = questionCategories
  },
  /**
   * 質問カテゴリー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qcid 質問カテゴリー情報のID
   * @param {Object} questionCategory 質問カテゴリー情報のオブジェクト
   */
  setQuestionCategory: (state, { qcid, questionCategory }) => {
    state.questionCategories = Object.assign(state.questionCategories, { [qcid]: questionCategory })
  },
  /**
   * stateのリセットを行う
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * 全ての質問カテゴリー情報を取得
   */
  async getQuestionCategories ({ commit }) {
    try {
      const snapshot = await getDocs(collection(db, 'question_categories'))

      const questionCategories = {}
      snapshot.forEach(doc => {
        questionCategories[doc.id] = Object.assign({ qcid: doc.id }, doc.data())
      })
      commit('setQuestionCategories', questionCategories)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 質問カテゴリー情報の作成
   * @param {Object} payload 質問カテゴリー情報
   */
  createQuestionCategory: async ({ commit }, payload) => {
    try {
      const docRef = await addDoc(collection(db, 'question_categories'), payload)
      commit('setQuestionCategory', { qcid: docRef.id, questionCategory: Object.assign({ qcid: docRef.id }, payload) })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
