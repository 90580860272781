import { db } from '@/firebase'
import { collection, doc, updateDoc, getDocs, addDoc, query, where, orderBy } from 'firebase/firestore'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全ての質問一覧を取得したかどうか
    isGotAll: false,
    // 全ての質問一覧
    allQuestions: [],
    // 診断の質問情報の一覧
    // { cid: { qid: {}, qid: {}, ... }, cid: { qid: {}, qid: {}, ... }, ... }
    questions: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object} 指定診断の質問情報の一覧
   */
  questions: state => cid => state.questions[cid] || {},
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全ての質問情報を取得済みかどうか
   */
  isGotAll: state => state.isGotAll,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 全ての質問情報の一覧
   */
  allQuestions: state => state.allQuestions,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @return {Object} 指定IDの質問情報
   */
  question: state => qid => state.allQuestions.find(question => question.qid === qid)
}

const mutations = {
  /**
   * cidに紐づく全ての質問をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid 診断内容のドキュメントID
   * @param {Object} payload.questions 質問情報の一覧
   */
  setQuestions: (state, payload) => {
    state.questions = Object.assign({}, state.questions, { [payload.cid]: payload.questions })
  },
  /**
   * 全ての質問をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} questions 前質問情報
   */
  setAllQuestions: (state, questions) => {
    state.allQuestions = questions
    state.isGotAll = true
  },
  /**
   * 作成した質問情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断内容のドキュメントID
   * @param {Object} params 追加した値（qidは付与しておく）
   */
  addQuestion: (state, { cid, params }) => {
    state.questions[cid] = {}
    state.questions[cid][params.qid] = {}
    state.questions[cid][params.qid] = params
  },
  /**
   * 質問情報の更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid ドキュメントID
   * @param {Obcjet} params 更新する値
   */
  updateQuestion: (state, { qid, params }) => {
    // cidの取得
    let cid = null
    Object.keys(state.questions).forEach(key => {
      if (Object.keys(state.questions[key]).includes(qid)) cid = key
    })
    // indexの取得
    const index = state.allQuestions.findIndex(question => question.qid === qid)

    // 質問情報の更新
    Object.keys(params).forEach(key => {
      if (cid) state.questions[cid][qid][key] = params[key]
      if (index) state.allQuestions[index][key] = params[key]
    })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定したcidに紐づく質問情報を作成
   * @param {Object} params 質問情報
   * @return {String} 質問情報のドキュメントID
   */
  addQuestion: async ({ commit }, params) => {
    try {
      const doc = await addDoc(collection(db, 'questions'), params)

      const cid = params.cid
      if (cid) commit('addQuestion', { cid: params.cid, params: Object.assign({ qid: doc.id }, params) })
      return doc.id
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 指定したcidに紐づく全質問の取得
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object} 質問情報の一覧
   */
  getQuestions: async ({ commit }, cid) => {
    try {
      const q = query(collection(db, 'questions'), where('cid', '==', cid))
      const snapshot = await getDocs(q)

      const questions = {}
      snapshot.forEach(doc => {
        questions[doc.id] = Object.assign(doc.data(), { qid: doc.id })
      })
      commit('setQuestions', { cid: cid, questions: questions })

      return questions
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 全質問の取得
   */
  getAllQuestions: async ({ commit }) => {
    try {
      const q = query(collection(db, 'questions'), orderBy('startAt', 'desc'))
      const snapshot = await getDocs(q)

      const questions = []
      snapshot.forEach(doc => {
        questions.push(Object.assign(doc.data(), { qid: doc.id }))
      })
      commit('setAllQuestions', questions)
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 全質問の取得
   * Db.vueで使用（使用後、削除対象）
   * @return {Object[]} 質問一覧
   */
  getAllQuestionsNotOrder: async ({ commit }) => {
    try {
      const q = query(collection(db, 'questions'))
      const snapshot = await getDocs(q)

      const questions = []
      snapshot.forEach(doc => {
        questions.push(Object.assign(doc.data(), { qid: doc.id }))
      })

      return questions
    } catch {
      router.push({ name: 'ErrorView' })
    }
  },
  /**
   * 質問の更新
   * @param {String} qid 質問情報のドキュメントID
   * @param {Object} params 更新内容
   */
  updateQuestion: async ({ commit }, { qid, params }) => {
    try {
      const docRef = doc(db, 'questions', qid)
      await updateDoc(docRef, params)

      commit('updateQuestion', { qid: qid, params: params })
    } catch {
      router.push({ name: 'ErrorView' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
